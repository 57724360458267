<template>
  <div class="d-flex flex-column align-items-center col-12 vue-container">
    <div v-if="windowWidth >= 500">
      <DesktopLogin/>
    </div>
    <div v-if="windowWidth < 500">
      <MobileLogin/>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

import DesktopLogin from "@/views/components/desktop/DesktopLogin";
import MobileLogin from "@/views/components/mobile/MobileLogin";
import {mapMutations} from "vuex";

export default {
  name: 'LoginPage',
  data() {
    return {
      windowWidth: windowWidth
    }
  },
  components: {DesktopLogin, MobileLogin},
  methods: {
    ...mapMutations(['setUserData']),
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
  beforeMount() {
    this.setUserData(undefined);
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap');

</style>
