<template>
  <div class="d-flex flex-column align-items-center col-12 vue-container">
    <div class="welcome-text col-10"> Welcome to the</div>
    <div class="mt-2 penrose-dashboard-text col-10">PENROSE INTERNAL DASHBOARD</div>
    <div class="login-text mt-4">Please login to your account</div>
    <div class="d-flex flex-column input-container">
      <input class="mt-3" type="text" v-model="user.email" placeholder="Email">
      <input @keyup.enter="loginClicked()" class="mt-3" type="password" v-model="user.password" placeholder="Password">
    </div>
    <button @click="loginClicked()" class="d-flex flex-row justify-content-start align-items-center mt-3 login-button">Login</button>
    <div class="mt-3 error-text" v-if="passwordIsValid === false">Invalid Email/Password</div>
    <div @click="routeToPage('forgot-password')" class="mt-3 text-decoration-underline">
      Forgot password?
    </div>
    <button @click="routeToPage('create-account')" class="mt-3 create-account-button">Create an account</button>
    <img class="mt-4 col-6" src="../../../assets/penrose-logo.png">
  </div>
</template>

<script>
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from 'vuex';
import bcrypt from 'bcryptjs';

export default {
  name: "MobileLogin",
  computed: {
    ...mapState(['userData']),
  },
  data() {
    return {
      user: {
        email: undefined,
        password: undefined,
      },
      userExists: undefined,
      passwordIsValid: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData']),
    async loginClicked() {
      await this.submitLogin();
      this.checkUserExists();
      this.compareEncryptedPassword();
      this.routeToDashboard();
    },
    async submitLogin() {
      const response = await DashboardService.getUserCredentials(this.user.email);
      this.setUserData(response.data.UserData);
    },
    checkUserExists() {
      this.userExists = this.userData === undefined;
    },
    compareEncryptedPassword() {
      this.passwordIsValid = bcrypt.compareSync(this.user.password, this.userData.Password);
    },
    routeToDashboard() {
      if (this.passwordIsValid) {
        this.routeToPage('dashboard')
      }
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    },
  },
}
</script>

<style scoped>
.vue-container {
  padding-top: 15px;
}

.welcome-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
}

.penrose-dashboard-text {
  font-size: 45px;
  color: #EE5C36;
  line-height: 1.1;
}

.login-text {
  font-size: 18px;
}

input {
  border: 0;
  height: 60px;
  border-radius: 12px;
  width: 270px;
  font-size: 14px;
  padding-left: 10px;
}

::placeholder {
  padding-left: 10px;
  font-size: 14px;
}

.login-button {
  height: 60px;
  width: 270px;
  font-size: 22px;
  padding-left: 24px;
}

.create-account-button, .login-button {
  background-color: #EE5C36;
  color: #FFFFFF;
  border: 0;
  border-radius: 12px;
}

.create-account-button {
  font-size: 14px;
  height: 35px;
  width: 175px;
}

.error-text {
  color: red;
}
</style>
